import React from 'react';
import './vu.css';

const AudioContext = window.AudioContext || window.webkitAudioContext;

class VU extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.__stream = null;
  }

  componentWillReceiveProps(props) {
    const id = props.stream && props.stream.id;
    if (id && (!this.__stream || this.__stream !== id)) {
      this.__stream = id;
      console.warn('saw a new streamId, re-registering');
      this.handleStream(props.stream);
    }
  }

  handleStream(stream) {
    // handle an updated stream reference
    if (this.__audioContext) {
      // kill any existing audioContext - this returns a promise, not sure we care.
      this.__audioContext.close();
    }

    this.__audioContext = new AudioContext();
    const analyser = this.__audioContext.createAnalyser();
    const microphone = this.__audioContext.createMediaStreamSource(stream);
    const scriptProcessorNode = this.__audioContext.createScriptProcessor(256, 1, 1);

    analyser.smoothingTimeConstant = 0.8;
    analyser.fftSize = 128;

    microphone.connect(analyser);
    analyser.connect(scriptProcessorNode);
    scriptProcessorNode.connect(this.__audioContext.destination);
    scriptProcessorNode.onaudioprocess = () => {
      // Fill an array with frequency information, add it all up, divide by number of frequency bins = average amplitude (volume) of incoming audio
      const frequencies = new Uint8Array(analyser.frequencyBinCount);
      analyser.getByteFrequencyData(frequencies);

      let totalAmplitude = 0;
      for (let i = 0; i < frequencies.length; i++) {
        totalAmplitude += (frequencies[i]);
      }

      const volume = totalAmplitude / frequencies.length;
      if (this.__vu) {
        this.__vu.style.width = `${Math.floor(volume)}%`;
      }


    };

  }

  render() {
    return (
      <div className='progress'>
        <div ref={ (el) => this.__vu = el } className='progress-bar vumeter'></div>
      </div>

    );
  }
}

export default VU;