import React from 'react';
import './App.css';
import mediaDevices from './media_devices';
import Vu from './vu';

class App extends React.Component {

  constructor(args) {
    super(args);
    this.state = {
      devices: {},
      defaults: {},
      selected: this.fromLocal('selected'),
      active: {},
    };

    mediaDevices.ondevicechange = this.updateDevices.bind(this);
  }

  fromLocal(key) {
    const foo = localStorage.getItem('selected');
    return (foo && foo.length && JSON.parse(foo)) || {};
  }

  toLocal(key, obj) {
    localStorage.setItem(key, JSON.stringify(obj));
  }

  componentDidMount() {
    const { selected } = this.state;
    this.updateDevices();
    if (this.__videoElement && this.__videoElement.setSinkId) {
      console.warn(this.__videoElement, this.__videoElement.setSinkId);
      this.__videoElement.setSinkId(selected.audiooutput);
    }
  }

  updateDevices() {
    mediaDevices.enumerateDevices().then(devices => {
      const newDevices = {};
      const defaults = {};
      devices.forEach(device => {
        const { deviceId, groupId, kind, label } = device;
        const [,, direction] = kind.match(/(\w+)(input|output)/i);

        if (!newDevices[kind]) {
          newDevices[kind] = {};
        }

        if (deviceId === 'default') {
          defaults[kind] = { label, deviceId, groupId };
        } else {
          newDevices[kind][deviceId] = {
            label, direction, groupId
          };
        }
      });
      this.getDevice();
      this.setState({ devices: newDevices, defaults });
    });
  }

  getDevice(type, device) {
    const { selected } = this.state;

    if (type) {
      selected[type] = device;
    }

    this.setState({ selected });
    this.toLocal('selected', selected);

    if (type === 'audiooutput') {
      if (this.__videoElement) {
        this.__videoElement.setSinkId(selected.audiooutput);
      }
    } else {
      mediaDevices.getUserMedia({
        audio: { deviceId: selected.audioinput },
        video: { deviceId: selected.videoinput },
      }).then(stream => {
        this.deconstructStream(stream);
      }).catch(e => {
        console.warn('GUM error', e);
      });
    }
  }

  showDefaults(defaults) {
    if (!defaults) {
      return "";
    }

    return Object.keys(defaults).map((key, idx) => {
      return (
        <React.Fragment key={ idx }>
          <dt className='col-sm-2'>{ key }</dt>
          <dd className='col-sm-10'>{ defaults[key].label }</dd>
        </React.Fragment>
      );
    });
  }

  showDevice(type, device) {
    const { selected } = this.state;
    return device && Object.keys(device).map((deviceId, idx) => {
      const u = `device-${idx}`;

      const className = ['list-group-item','list-group-item-action'];
      if (selected[type] && selected[type] === deviceId) {
        className.push('list-group-item-primary')
      }

      return (
        <a key={ u } className={ className.join(' ') } onClick={ (e) => this.getDevice(type, deviceId) }>{ device[deviceId].label }</a>
      );
    });
  }

  showDevices(devices) {
    if (!devices) {
      return '';
    }

    return Object.keys(devices).map((key, idx) => {
      const u = `devices-${idx}`;
      return (
        <div className='col' key={ u }>
          <h5>{ key }</h5>
          <div className='list-group-flush'>
            { this.showDevice(key, devices[key]) }
          </div>
        </div>
      )
    });
  }

  deconstructStream(stream) {
    if (this.__videoElement) {
      this.__videoElement.poster = '';
      this.__videoElement.srcObject = stream;
    }
    this.__stream = stream;
    const tracks = stream.getTracks();
    const active = {};
    tracks.forEach(track => {
      active[track.kind] = track;
    });
    this.setState({ active });
  }

  showActive(devices) {
    if (!devices) {
      return "";
    }

    return Object.keys(devices).map(deviceType => {
      return (
        <p>{ deviceType }: { devices[deviceType].label }</p>
      )
    });
  }

  render() {
    const { devices, defaults, active } = this.state;
    return (
      <div className="container">
        <h2><code>enumerateDevices / getUserMedia</code></h2>
        <div className='row jumborow'>
          <div className='col'>
            <video autoplay='' playsinline ref={ (e) => this.__videoElement = e }></video>
            <Vu stream={ this.__stream } />
          </div>
          <div className='col'>
            <h5>active:</h5>
            { this.showActive(active) }
            <h5>defaults</h5>
            { this.showDefaults(defaults) }
          </div>
        </div>
        <h4>available devices:</h4>
        <div className='row'>
          { this.showDevices(devices) }
        </div>
      </div>
    );
  }
}

export default App;
